<template>

	<div>
		<header class="header">
			<div class="header">
				<h1 class="logo">
					<a href="/">WAPU</a>
				</h1>
				<nav class="gnb">
					<ul class="dep1_list">

						<template v-if="!isGuest">
							<li class="dep1_item">
								<a href="/support/serviceInfo" class="dep1_link">서비스 소개</a>
							</li>
							<li class="dep1_item">
								<a href="/service/info" class="dep1_link">서비스 신청</a>
							</li>
							<li class="dep1_item">
								<a href="/myService/myList" class="dep1_link">MY 서비스</a>
								<ul class="dep2_list">
									<li class="dep2_item"><a href="/myService/myList" class="dep2_link">나의 서비스</a></li>
									<li class="dep2_item"><a href="/myService/analysis" class="dep2_link">분석 보고서</a></li>
									<li class="dep2_item"><a href="/myService/monitoring" class="dep2_link">모니터링 보고</a></li>
									<li class="dep2_item"><a href="/myService/historyList" class="dep2_link">결제이력보기</a></li>
									<li class="dep2_item"><a href="/myService/paymentOption" class="dep2_link">결제정보관리</a></li>
									<li class="dep2_item"><a href="/myService/inviteList" class="dep2_link">초대하기</a></li>
								</ul>
							</li>
							<li class="dep1_item">
								<a href="/board/cms_notice" class="dep1_link">고객센터</a>
								<ul class="dep2_list">
									<li class="dep2_item"><a href="/board/cms_notice" class="dep2_link">공지사항</a></li>
									<li class="dep2_item"><a href="/faq" class="dep2_link">FAQ</a></li>
									<li class="dep2_item"><a href="/support/qna" class="dep2_link">문의하기</a></li>
								</ul>
							</li>
							<li class="dep1_item">
								<a href="/review/cms_board" class="dep1_link">사용후기</a>
							</li>
						</template>

						<template v-if="isGuest">
							<li class="dep1_item">
								<a href="/myService/analysis" class="dep1_link">분석 보고서</a>
							</li>
							<li class="dep1_item">
								<a href="/myService/monitoring" class="dep1_link">모니터링 보고</a>
							</li>
						</template>

					</ul>
				</nav>
        <div class="util">
          <button type="button" @click="togleClass" class="btn_user"><span class="blind">로그인</span></button>
          <div class="user_layer" :class="this.isActive?'active':''">
            <ul class="user_list">
              <li v-if="!isLogin()"><a href="/member/login">로그인</a></li>
              <li v-if="isLogin()" @click="doLogout"><a href="javascript:;">로그아웃</a></li>
              <li v-if="!isLogin()"><a href="/member/signUp">회원가입</a></li>
            </ul>
          </div>
          <button type="button" class="btn_sitemap"><span class="blind">사이트맵</span></button>
        </div>
			</div>
		</header>

	</div>


</template>


<script>

import HWAPP from '@/commons/mixins/hwapp.mixin'
// import $ from "jquery";
// import webUtils from "@/commons/utils/web.utils";


export default {
	name: "partialHeader",
	mixins: [
		HWAPP
	],
	data() {
		return {
			isGuest: false,
			pathname: location.pathname,
			isActive:false
		}
	},
	beforeCreate() {
		let self = this

		window.onload = function () {
			window.addEventListener('message', event => {
				let allowOrigin = event.origin

				if (event.origin === new URL(allowOrigin).origin) {

					if (event.target.localStorage["vue-session-key"] !== undefined) {

						let sesionKey = JSON.parse(event.target.localStorage["vue-session-key"]);

						//console.log('sesionKey.HWSSessions',sesionKey.HWSSessions)
						if (sesionKey.HWSSessions != undefined) {
							self.$session.set("HWSSessions", sesionKey.HWSSessions)
						}

					}

				}

				if (event.data.key === "alert") {
					self.swalUtils.gritter(event.data.title, event.data.content, event.data.icon, 1500)
				}

				if (event.data.key === "go") {
					location.href = event.data.to
				}
			}, false);
		}
	},

	async created() {
	},

	mounted() {

		const gtmScript = document.createElement("script");
		gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WGMZM3NT');
    `;
		document.head.appendChild(gtmScript);

		// Mirae Script Ver 2.0
		const miraeScript = document.createElement('script');
		miraeScript.async = true;
		miraeScript.src = '//log1.toup.net/mirae_log_chat_common.js?adkey=sjsgf';
		miraeScript.charset = 'UTF-8';

		document.head.appendChild(miraeScript);

		if( this.isLogin() ){
			if(this.getSessionProc().isGuest) {
				this.isGuest = this.getSessionProc().isGuest
			}
		}

		//this.$eventBus.$emit("initApp");

	},

	methods: {
		togleClass () {
			if(this.isActive) {
				this.isActive=false
			} else {
				this.isActive=true
			}
		}
	},

}
</script>

<style scoped>
</style>
