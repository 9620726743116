<template>

	<div id="app">

		<partial-header></partial-header>

		<!--<transition name="fade" mode="out-in">
			<router-view name="rv-container" :key="$route.fullPath"></router-view>
		</transition>-->

		<router-view name="rv-container" :key="$route.fullPath"></router-view>

		<partial-footer></partial-footer>

	</div>

</template>

<script>
import PartialHeader from "@/components/layouts/partialHeader";
import PartialFooter from "@/components/layouts/partialFooter";


export default {
	name: "layoutPage",
	components: {PartialFooter, PartialHeader},
	created() {

	},
	mounted() {

	},
	updated() {

	}
}
</script>

<style scoped>

</style>
